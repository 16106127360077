<template>
  <div class="capitulo">
    <v-layout row style="margin:0px!important">
      <v-flex xs12 md9>
        <v-flex v-if="ilustre==null" class="justify-center" style="text-align:center;margin-top:40px">
          <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
        </v-flex>
        <v-card v-if="ilustre!=null" class="pb-5">
          <v-card-title primary-title>
            <div style="width:100%">
              <h3 class="headline" style="margin-bottom: 20px!important;">{{ilustre.titulo}}</h3>
              <div class="body-2 pl-1 mb-3" style="margin-top: -20px;">{{getFecha(ilustre)}}</div>
              <iframe :src="getYoutubeEmbeded(ilustre.link)" :title="ilustre.titulo" frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" style="width: 100%;aspect-ratio: 16/9;"
                  referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              <div v-html="ilustre.texto" class="body-1" style="text-align:justify;word-break: normal!important;"></div>
            </div>
          </v-card-title>
          <v-card-actions :style="getLinksStyle()">
            <v-btn class="btn-link-ilustre" v-if="ilustre.ivoox!=null" color="orange" dark :href="ilustre.ivoox" target="_blank"> 
              <img :src="require('../assets/logo_ivoox_sml.png')" style="padding-right:5px" />{{getBtnPrefix() + 'Ivoox'}}
            </v-btn>
            <v-btn class="btn-link-ilustre" v-if="$root.$isAdmin" color="blue darken-4" dark :href="'#/ilustre-editor/' + ilustre.id"> 
              <v-icon>mdi-pen</v-icon>Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex class="hidden-sm-and-down" md3>
        <h3 style="text-align:center;padding:10px;">También te puede interesar</h3>
        <v-flex v-if="relacionados==null" class="justify-center" style="text-align:center">
          <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
        </v-flex>
        <v-flex v-if="relacionados!=null">
        <v-card v-for="(rel,i) in relacionados" :key="i" style="margin:10px" class="elevation-8" :href="'/capitulo/' + rel.numero" >
          <v-img v-if="rel.imagen!=null" :src="getImageUrl(rel)" height="200px" class="white--text align-end">
            <v-card-title primary-title class="pl-3 pr-3 pt-0 pb-1 txt-relacionado">
              <div>
                <h3 class="title mb-0" style="font-size:16px!important">{{rel.nombre}}</h3>
                <div class="body-2 pl-1" style="margin-top: -5px;">{{getFecha(rel)}}</div>
              </div>
            </v-card-title>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="red lighten-2"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-snackbar v-model="snackbar" color="error" top>{{ textoError }}
      <v-btn dark text @click="snackbar = false">
        Cerrar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: "ilustre",
  components: {
  },
  data() {
    return {
      ilustre: null,
      relacionados: null,
      snackbar:false,
      textoError: null
    };
  },
  created() {
    const idIlustre=this.$route.params.id;
    this.$http.get(this.$root.$basepath + "api/ilustres.php?i=" + idIlustre).then(result => {
        result.json().then(ilustre =>{
          this.ilustre = ilustre;
        });
    }, error => {
        this.mostrarError(error);
    });
    this.$http.get(this.$root.$basepath + "api/ilustres.php?r=" + idIlustre).then(result => {
      result.json().then(relacionados =>{
        this.relacionados = relacionados;
      });
    }, error => {
      this.mostrarError(error);
    });
  },
  mounted(){
    this.$vuetify.goTo(0, {duration: 500,easing: 'linear'});
  },
  methods: {
    getYoutubeEmbeded: function(youtubeLink){
      return youtubeLink.replace('watch?v=','embed/')
    },
    getLinksStyle: function(){
			if (this.$vuetify.breakpoint.xs){
				return 'flex-wrap:wrap;justify-content: space-evenly';
			}
			return 'flex-wrap:wrap;justify-content: space-between';
		},
		getBtnPrefix: function(){
			if (this.$vuetify.breakpoint.xs){
				return '';
			}
			return 'Escuchar en '; 
		},
    getImageUrl: function(relacionado){
			return this.$root.$basepath + 'api/thumbnail.php?ty=ca&i=' + encodeURIComponent(relacionado.imagen) + this.$root.$webp;
    },
		getFecha: function (rel){
			return dayjs(rel.fecha).format('DD/MM/YYYY');
    },
    mostrarError(texto){
      this.textoError=texto;
      this.snackbar=true;
    }
  }
};
</script>
<style>
.txt-relacionado{
   background:linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0.3) 20%, rgba(0,0,0,0.5) 100%);
}
.btn-link-ilustre{
  margin-bottom:5px!important;
}
</style>